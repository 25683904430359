import React, { useState } from 'react';

const FetchDataComponent = () => {
  const [url, setUrl] = useState('');
  const [category, setCategory] = useState('');
  const [productId, setProductId] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFetchData = async () => {
    setLoading(true);
    setError(null);
    setResponseData(null);

    try {
      const response = await fetch('/productData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url, category, productId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch data');
      }

      const data = await response.json();
      setResponseData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Fetch Data from URL</h1>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter a URL"
        style={{ width: '80%', marginRight: '10px' }}
      />
      <input
        type="text"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        placeholder="Enter a URL"
        style={{ width: '80%', marginRight: '10px' }}
      />
      <input
        type="text"
        value={productId}
        onChange={(e) => setProductId(e.target.value)}
        placeholder="Enter a URL"
        style={{ width: '80%', marginRight: '10px' }}
      />

      <button onClick={handleFetchData} disabled={loading || !url}>
        {loading ? 'Loading...' : 'Fetch Data'}
      </button>
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
      {responseData && (
        <div style={{ marginTop: '20px' }}>
          <h3>Response Data:</h3>
          <p><strong>Title:</strong> {responseData.title || 'N/A'}</p>
          <p><strong>Price:</strong> {responseData.price || 'N/A'}</p>
          <p>
            <strong>Image:</strong>{' '}
            {responseData.imageSrc ? (
              <img
                src={responseData.imageSrc}
                alt="Fetched data"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            ) : (
              'N/A'
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default FetchDataComponent;
