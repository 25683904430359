import React from 'react';
import './DomainsList.css';
import SmallLogo from './icons/SmallLogo';

const DomainsList = () => {
  return (
    <div className="body">
      <div className="container">
        <nav className="navbar">
          <SmallLogo />
          <button className="mainButton">Contact Us</button>
        </nav>
        <div className="list">
          <div className="list-item">
            <div className="list-item-header">
              <h2 className="title">Swiftiverse.com</h2>
              <div className="right-side">
                <span className="price">Requested price: <strong>$19900</strong></span>
                <button className="mainButton">Make an offer</button>
              </div>
            </div>
            <img className='image' src="/swiftiverse_banner.svg" alt="Nuance banner"/>
            </div>
          <div className="list-item">
            <div className="list-item-header">
              <h2 className="title">Nuance.press</h2>
              <div className="right-side">
                <span className="price">Requested price: <strong>$1990</strong></span>
                <button className="mainButton">Make an offer</button>
              </div>
            </div>
            <img className='image' src="/nuance_banner.svg" alt="Nuance banner"/>
          </div>
          <div className="list-item">
            <div className="list-item-header">
              <h2 className="title">Chillmoon.xyz</h2>
              <div className="right-side">
              <span className="price">Requested price: <strong>$290</strong></span>
              <button className="mainButton">Make an offer</button>
              </div>
            </div>
            <img className='image' src="/nuance_banner.svg" alt="Nuance banner"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainsList;