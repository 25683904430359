import React from 'react';

const MainLogo = () => {
  return (
    <a href="/" className='mainLogo' target="_blank" rel="noopener noreferrer">
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40741 0.632812H32.5926C36.6836 0.632812 40 3.94922 40 8.04022V33.2254C40 37.3164 36.6836 40.6328 32.5926 40.6328H7.40741C3.31641 40.6328 0 37.3164 0 33.2254V8.04022C0 3.94922 3.31641 0.632812 7.40741 0.632812ZM7.40741 4.33652C5.36191 4.33652 3.7037 5.99472 3.7037 8.04022V33.2254C3.7037 35.2709 5.36191 36.9291 7.40741 36.9291H32.5926C34.6381 36.9291 36.2963 35.2709 36.2963 33.2254V8.04022C36.2963 5.99472 34.6381 4.33652 32.5926 4.33652H7.40741Z" fill="#0057FF"/>
        <path d="M15.2701 18.2491L15.0466 17.1179C14.3391 18.0304 13.4266 18.4866 12.3094 18.4866C10.8104 18.4866 9.60933 17.9838 8.70622 16.9783C7.84034 16.0193 7.40741 14.795 7.40741 13.3053C7.40741 11.7784 7.84034 10.5308 8.70622 9.56248C9.60002 8.54764 10.8011 8.04022 12.3094 8.04022C13.3801 8.04022 14.3065 8.34281 15.0885 8.94799C15.9265 9.59972 16.4013 10.4703 16.513 11.5596H14.4182C14.2972 11.0196 14.0458 10.6053 13.664 10.3166C13.2916 10.028 12.8401 9.88369 12.3094 9.88369C11.3876 9.88369 10.6893 10.2375 10.2145 10.9451C9.80485 11.5503 9.60002 12.337 9.60002 13.3053C9.60002 14.2363 9.80485 14.9998 10.2145 15.5957C10.6987 16.2939 11.3969 16.6431 12.3094 16.6431C13.7618 16.6431 14.5532 15.9262 14.6835 14.4924H12.477V12.8584H16.6667V18.2491H15.2701Z" fill="#0057FF"/>
        <path d="M7.40741 29.5217H32.5926V33.2254H7.40741V29.5217Z" fill="#0057FF"/>
        <path d="M7.40741 22.1143H32.5926V25.818H7.40741V22.1143Z" fill="#0057FF"/>
      </svg>
    </a>
  );
}; 

export default MainLogo;